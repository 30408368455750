import { modifier } from 'ember-modifier';
import { addListener, removeListener } from 'resize-detector';

// USE REACTIVE WEB DEBOUNCE INSTEAD, only doing this as a short term fix to get rid of lodash-es
function debounce<T extends (...args: unknown[]) => void>(callback: T, wait = 0): T {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  // Return a function that debounces calls to `callback`
  return function debounced(this: unknown, ...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      callback.apply(this, args);
    }, wait);
  } as T;
}

/**
 * Calls the `callback` whenever `element` is resized.
 *
 * ## Options
 *
 * `debounce`
 * : The amount of time to wait before `callback` is called. If another resize
 *   event occurs while waiting, the time to wait is reset
 */

type CallbackFunction = (arg: HTMLElement) => void;
type Options = { debounce: number };

export default modifier(
  (element: HTMLElement, [callback]: [CallbackFunction], options: Options) => {
    // @ts-expect-error: who knows. Seems like this modifier should use a lib
    const resized = debounce(callback, options?.debounce ?? 0);

    addListener(element, resized);

    return () => removeListener(element, resized);
  },
  // @ts-expect-error: There is an issue with modifiers not exposing this correctly.
  { eager: false }
);
