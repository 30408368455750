// check with team on approach we want here
import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import type StoreService from '@ember-data/store';
import type { UploadFile } from 'ember-file-upload';
import type FileHistoryModel from '../models/file-history';
import type DigitalAssetsService from './digital-assets';
import type Company from 'tio-common/models/company';

export default class DataTransferAgentService extends Service {
  @service declare store: typeof StoreService;
  @service declare digitalAssets: DigitalAssetsService;

  //not part of tuition-assistance service to keep it clean and use outside of TA if needed.

  @action
  async createDataTransferAgentFileHistory(
    uploadFile: UploadFile,
    options: { company: Company; operationId: string }
  ): Promise<FileHistoryModel> {
    const { company, operationId } = options;
    //const file_size = uploadFile.file.size;

    // get API path for raw file assets
    const collectionPath = this.store
      .adapterFor('application')
      .urlForCreateRecord('raw-file-digital-asset');

    // set headers
    const headers = this.store.adapterFor('application')?.headers;
    headers['Content-Type'] = 'application/vnd.api+json';

    // create asset record through API
    const assetResponse = await fetch(`${collectionPath}/s3-signed-url`, {
      headers: headers,
      method: 'POST',
      body: JSON.stringify({ filename: uploadFile.file.name, public_image: false }),
    });

    // read asset response content and upload binary to signed url
    const assetDetails = await assetResponse.json();
    await uploadFile.uploadBinary(assetDetails.url, { method: 'PUT' });

    // create and save raw file asset
    const rawFileDigitalAsset = this.digitalAssets.createAssetFromS3Details(
      'raw-file-digital-asset',
      assetDetails
    );
    await rawFileDigitalAsset.save(); // verify the saving part. what exactly gets saved for digitalassets

    const contentType = rawFileDigitalAsset.contentType;
    // create and save migration file history
    const recordType = 'PartnerDataTransfer';

    const dataTransferAgentFileHistory = this.store.createRecord('file-history', {
      rawFileDigitalAsset: rawFileDigitalAsset,
      company: company,
      operationId: operationId,
      contentType: contentType,
      recordType: recordType,
    });

    await dataTransferAgentFileHistory.save();
    return dataTransferAgentFileHistory;
  }

  //re-visit this method for preprocessed
  @action
  updateDataTransferAgentFileHistory(attributes: Record<string, unknown>): FileHistoryModel {
    return this.store.push({
      data: [
        {
          type: 'file-history',
          id: attributes.id,
          attributes,
        },
      ],
    });
  }
}
