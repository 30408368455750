import Model, { attr } from '@ember-data/model';

export default class TasUnifiedInstitutionModel extends Model {
  @attr declare isFeatured: boolean;
  @attr declare isPreferred: boolean;

  @attr declare dapipId: number;
  @attr declare institutionId: string;
  @attr declare address: string;
  @attr declare locationName: string;
  @attr declare locationType: string;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-unified-institution': TasUnifiedInstitutionModel;
  }
}
