import { buildYup } from 'schema-to-yup';
import { object } from 'yup';
import type TasProgramTemplateModel from '../../models/tas-program-template.ts';
import type { TASProgramTemplateModelFieldsSignature } from '../../models/tas-program-template.ts';
import type { TASApplicationModelFieldsSignature } from '../../models/tas-application.ts';
import type { TASProgramInstanceModelFieldsSignature } from '../../models/tas-program-instance.ts';
import type {
  GenericTemplateField,
  CustomFieldSignature,
  TemplateApprovalWorkflowApprover,
} from '../../types/tuition-assistance';
import type { TASCourseModelFieldsSignature } from '../../models/tas-course.ts';
import type TasFieldOptionModel from '../../models/tas-field-option.ts';

/**
 * @remarks
 * This type serves as a catch-all for FieldsSignature types for the various models.
 * It serves a purpose for typing utility methods that operate on any one of the FieldsSignature types,
 * but it is not meant to be used as a type when you know which model you are working with.
 * In that case, you should use the specific FieldsSignature type for that model.
 */
export type TASFields =
  | TASProgramTemplateModelFieldsSignature
  | TASApplicationModelFieldsSignature
  | TASProgramInstanceModelFieldsSignature
  | TASCourseModelFieldsSignature;

export type FieldValue =
  | string
  | boolean
  | number
  | null
  | Record<string, unknown>
  | TemplateApprovalWorkflowApprover;

export type TemplateFieldName = keyof TASProgramTemplateModelFieldsSignature;

export type FieldName =
  | TemplateFieldName
  | keyof TASApplicationModelFieldsSignature
  | keyof TASProgramInstanceModelFieldsSignature
  | keyof TASCourseModelFieldsSignature;

export function setSingleValueForTasField(
  fieldName: keyof TASProgramTemplateModelFieldsSignature,
  value: FieldValue,
  fieldsObject: TASProgramTemplateModelFieldsSignature
): void;
export function setSingleValueForTasField(
  fieldName: keyof TASProgramInstanceModelFieldsSignature,
  value: FieldValue,
  fieldsObject: TASProgramInstanceModelFieldsSignature
): void;
export function setSingleValueForTasField(
  fieldName: keyof TASApplicationModelFieldsSignature,
  value: FieldValue,
  fieldsObject: TASApplicationModelFieldsSignature
): void;
export function setSingleValueForTasField(
  fieldName: keyof TASCourseModelFieldsSignature,
  value: FieldValue,
  fieldsObject: TASCourseModelFieldsSignature
): void;
export function setSingleValueForTasField(
  fieldName: FieldName,
  value: FieldValue,
  fieldsObject: TASFields
): void {
  if (!fieldName || !fieldsObject) {
    return;
  }

  // @ts-expect-error - Cory this is the issue that is causing so many problems...
  // Element implicitly has an 'any' type because expression of type 'FieldName' can't be used to index type 'TASFields'.
  const existingFieldConfig = fieldsObject[fieldName] || {};

  const updatedValues = value === undefined ? [] : [value];

  // @ts-expect-error Figure out how to type to avoid this
  // "Type 'FieldValue' is not assignable to type 'never'. Type 'null' is not assignable to type 'never'."
  fieldsObject[fieldName] = { ...existingFieldConfig, values: updatedValues };
}

export function updateTasTemplateFieldConfig(
  fieldName: TemplateFieldName,
  updatedObject: Partial<GenericTemplateField>,
  fieldsObject: TASProgramTemplateModelFieldsSignature
) {
  if (!fieldName || !fieldsObject) {
    return;
  }

  const existingFieldConfig = fieldsObject[fieldName] || {};

  // @ts-expect-error Figure out how to type so it can properly index the keys
  // "Element implicitly has an 'any' type because expression of type 'FieldName' can't be used to index type 'TASFields'."
  fieldsObject[fieldName] = { ...existingFieldConfig, ...updatedObject };
}

export function getSingleValueForTasField<
  T extends TASFields,
  F extends TASCourseModelFieldsSignature | FieldValue | undefined,
>(fieldName: FieldName, fieldsObject: T | undefined, defaultValue?: F): F {
  if (!fieldName || !fieldsObject) {
    return defaultValue as F;
  }
  // @ts-expect-error: Typescript cannot guarantee that a union of keys (from FieldName) will always match the union of object types (TASFields).
  // This was dealt with partially using the exported overloads above, which will enforce valid key/value pairs in the consuming code.
  // Yet this fallback TS error still happens.
  const value = fieldsObject[fieldName]?.values?.[0];

  if (value === undefined) {
    return defaultValue as F;
  }

  return value as F;
}

export function getAllValuesForTasField(
  fieldName: keyof TASProgramTemplateModelFieldsSignature,
  fieldsObject: TASProgramTemplateModelFieldsSignature
): FieldValue[];
export function getAllValuesForTasField(
  fieldName: keyof TASProgramInstanceModelFieldsSignature,
  fieldsObject: TASProgramInstanceModelFieldsSignature
): FieldValue[];
export function getAllValuesForTasField(
  fieldName: keyof TASApplicationModelFieldsSignature,
  fieldsObject: TASApplicationModelFieldsSignature
): FieldValue[];
export function getAllValuesForTasField(
  fieldName: keyof TASCourseModelFieldsSignature,
  fieldsObject: TASCourseModelFieldsSignature
): FieldValue[];
export function getAllValuesForTasField(fieldName: FieldName, fieldsObject: TASFields) {
  if (!fieldName || !fieldsObject) {
    return [];
  }

  // @ts-expect-error: Typescript cannot guarantee that a union of keys (from FieldName) will always match the union of object types (TASFields).
  // This was dealt with partially using the exported overloads above, which will enforce valid key/value pairs in the consuming code.
  // Yet this fallback TS error still happens.
  return fieldsObject[fieldName]?.values || [];
}

/**
 * This function is used to get the config for a field on a program template.
 * It is used in the program builder to display the field config in the UI.
 * For convenience, it also returns the first value in the values array as `value`.
 * The return value is readonly.
 *
 * @param fieldName
 * @param fieldsObject
 * @returns TemplateFieldConfigAndValues
 *
 */

export type ReadonlyTemplateFieldConfigAndValues = Readonly<
  GenericTemplateField & { value: FieldValue | undefined; name: TemplateFieldName }
>;

export function getConfigForTasField(
  fieldName: TemplateFieldName,
  fieldsObject: TASProgramTemplateModelFieldsSignature
): ReadonlyTemplateFieldConfigAndValues {
  if (!fieldName || !fieldsObject || !fieldsObject[fieldName]) {
    console.warn(`Unable to resolve config for TAS field ${fieldName}. Returning empty config.`);
    return {
      name: fieldName,
      label: '',
      province: 'PROGRAM',
      required: false,
      visible: false,
      values: [],
      value: undefined,
    };
  }
  const config = fieldsObject[fieldName] as GenericTemplateField;
  return { ...config, value: config.values?.[0], name: fieldName };
}

/**
 * When a TasProgramInstance, TasApplication, or TasCourse is created, we copy the relevant custom fields
 * from the program template and set them on the model at the time of creation.
 * (We copy these arrays from `instanceCustomFields`, `applicationCustomFields`, and `courseCustomFields` on TasProgramTemplate model)
 *
 * This does not handle the edge case where the custom fields on the parent TasProgramTemplate might be edited after
 * the child instance/application/course has already been created. We need to preserve the values they might have stored, but update
 * the overall array of fields to include new ones or any changes to requirements.
 *
 * IMPORTANT:
 * The arrays of fields passed as arguments to this function should already be filtered by the proper model type. For example,
 * if we are passing in `TasProgramInstance.customFields` as `existingFields`, then we should pass in `TasProgramTemplate.instanceCustomFields`
 * as the `templateCustomFields`. See mapping below:
 *         `TasProgramInstance.customFields` and `TasProgramTemplate.instanceCustomFields`
 *         `TasApplication.customFields` and `TasProgramTemplate.applicationCustomFields`
 *         `TasCourse.customFields` and `TasProgramTemplate.courseCustomFields`
 *
 */
export function copyFieldsAndUpdatePerProgramTemplate(
  existingCustomFields: CustomFieldSignature[] = [],
  templateCustomFields: CustomFieldSignature[] = []
): CustomFieldSignature[] {
  // Make a clean copy of the existing custom fields
  const resolvedFields = existingCustomFields.map((field) => {
    return { ...field };
  });

  // Loop through the custom fields defined by the program template
  // and check to see if they exist already.
  templateCustomFields.forEach((programField) => {
    const existingField = resolvedFields.find((instanceField) => {
      // TAS.TODO: This is extremely brittle. We need to add a more unique ID to custom fields in the program builder.
      return instanceField.label === programField.label;
    });

    // If the field does not exist yet, add it to the resolved fields.
    // Otherwise, update the existing field with the config from program template
    // but preserve the values stored.
    if (!existingField) {
      resolvedFields.push({ ...programField });
    } else {
      const indexOfMatchingField = resolvedFields.indexOf(existingField);
      resolvedFields[indexOfMatchingField] = { ...programField, values: existingField.values };
    }
  });

  // We are purposely not going to remove old custom fields that might have been present on the program template but were deleted
  // from the program config. This ensures that we do not lose information that a user might have input that might be relevant
  // to the timeframe in which their instance/application/course exists.

  return resolvedFields;
}

export function getCustomFieldObjectsForProvince(
  province: string,
  customFieldsArray: CustomFieldSignature[]
): CustomFieldSignature[] {
  // Filter customFields by province, sort by ordinal, map and return only necessary keys
  const customFields = customFieldsArray
    .filter((obj) => obj.province === province)
    .sort((a, b) => a.ordinal - b.ordinal);

  return customFields;
}

export type ValidationField = {
  name: keyof TASProgramTemplateModelFieldsSignature;
  rules?: Record<string, unknown>;
  errors?: Record<string, unknown>;
};

// https://github.com/kristianmandrup/schema-to-yup
export function buildValidationSchemaForProgramTemplateFields(
  fieldsToValidate: ValidationField[] = [],
  programTemplate: TasProgramTemplateModel
) {
  const fieldsConfig: TASProgramTemplateModelFieldsSignature = programTemplate?.fields || {};

  try {
    const properties = {};
    const errMessages = {};
    const requiredFieldNames: string[] = [];

    fieldsToValidate.forEach(({ name, rules = {}, errors = {} }) => {
      const isRequired = !!fieldsConfig[name]?.required;
      // @ts-expect-error Julia: can we create a list of property keys or is it a record?
      properties[name] = {
        required: isRequired,
        ...rules,
      };
      if (isRequired) {
        requiredFieldNames.push(name);
        const message = fieldsConfig[name]?.label
          ? `${fieldsConfig[name]?.label} is required`
          : 'Required';
        // @ts-expect-error Julia: static list of keys
        errMessages[name] = {
          required: message,
          ...errors,
        };
        // @ts-expect-error Julia: static list of keys
      } else errMessages[name] = { ...errors };
    });
    const schema = {
      $schema: 'http://json-schema.org/draft-07/schema#',
      name: 'test',
      type: 'object',
      properties,
      required: requiredFieldNames,
    };
    const config = { errMessages };
    const yupSchema = buildYup(schema, config);
    return yupSchema;
  } catch (e) {
    console.error('Error setting up yup schema for program template fields', e, {
      fieldsToValidate,
      programTemplateId: programTemplate?.id,
      fieldsConfig,
    });
    return object();
  }
}

// https://github.com/kristianmandrup/schema-to-yup
export function buildValidationSchemaForCustomFields(
  fieldsToValidate: CustomFieldSignature[] = []
) {
  try {
    const properties = {};
    const errMessages = {};
    const requiredFieldNames: string[] = [];

    const fields = fieldsToValidate.map((field) => {
      return {
        name: field.label, // Label is our only unique ID on these. Need to introduce unique identifier.
        label: field.label,
        required: field.required,
        rules: field.validation?.yup?.rules || {},
        errors: field.validation?.yup?.errors || {},
      };
    });

    fields.forEach(({ name, label, required, rules = {}, errors = {} }) => {
      const isRequired = !!required;
      // @ts-expect-error Julia: can we create a list of property keys or is it a record?
      properties[name] = {
        required: isRequired,
        ...rules,
      };
      if (isRequired) {
        requiredFieldNames.push(name);
        const message = label ? `${label} is required` : 'Required';
        // @ts-expect-error Julia: static keys?
        errMessages[name] = {
          required: message,
          ...errors,
        };
        // @ts-expect-error Julia: static keys?
      } else errMessages[name] = { ...errors };
    });
    const schema = {
      $schema: 'http://json-schema.org/draft-07/schema#',
      name: 'test',
      type: 'object',
      properties,
      required: requiredFieldNames,
    };
    const config = { errMessages };
    const yupSchema = buildYup(schema, config);
    return yupSchema;
  } catch (_e) {
    console.error('Error setting up yup schema for custom fields', {
      fieldsToValidate,
    });
    return object();
  }
}

export function buildCustomFieldsFormModelForValidation(customFields: CustomFieldSignature[] = []) {
  const entries = customFields.map(({ label, values }) => {
    const keyValue = values.length > 1 ? values : values[0];
    const keyName = label;
    return [keyName, keyValue];
  });

  return Object.fromEntries(entries);
}

export function getSortedOptionsByFieldName(
  fieldName: TemplateFieldName,
  fieldOptions: TasFieldOptionModel[] = []
): TasFieldOptionModel[] {
  return fieldOptions
    .slice()
    .filter((option) => option.fieldName === fieldName)
    .sort((a, b) => {
      if (a.ordinal === b.ordinal) {
        return 0;
      }

      return a.ordinal > b.ordinal ? 1 : -1;
    });
}

export function getFieldLabelForFieldName(
  fieldName: TemplateFieldName,
  fieldsObject: TASProgramTemplateModelFieldsSignature
): string {
  return fieldsObject[fieldName]?.label;
}

export default null; // silence a false warning
