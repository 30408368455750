import { attr, belongsTo } from '@ember-data/model';
import DigitalAsset from './digital-asset.ts';
import type FileHistory from './file-history.ts';

export default class RawFileDigitalAssetModel extends DigitalAsset {
  /*************************
   **  Attributes         **
   *************************/
  @attr declare description: string;
  @attr declare contentType: 'application/vnd.api+json';
  @attr declare classification: string;
  @attr declare externalUrl: string;

  /*************************
   **  Relationships      **
   *************************/
  @belongsTo('file-history', { async: false, inverse: 'rawFileDigitalAsset' })
  declare fileHistory: FileHistory;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    rawFileDigitalAsset: RawFileDigitalAssetModel;
  }
}
