import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { cached } from '@glimmer/tracking';
import type CompanyModel from './company.ts';
import type ConditionModel from './condition.ts';
import type TasGroupModel from './tas-group.ts';
import type TasGroupingModel from './tas-grouping.ts';
import type TASProgramInstanceModel from './tas-program-instance.ts';
import type {
  CustomFieldSignature,
  StringTemplateSingleValueField,
  StringTemplateMultipleField,
  NumberTemplateSingleValueField,
  BooleanTemplateSingleValueField,
  ObjectTemplateSingleValueField,
  ApprovalWorkflowTemplateField,
  ScholarshipsReceivedTemplateField,
  TasProgramTemplateState,
  ClaimsFinancialsTemplateSingleValueField,
} from '../types/tuition-assistance.ts';
import type TasFieldOptionModel from './tas-field-option.ts';
import type TasProgramTemplateVersionModel from './tas-program-template-version.ts';
import type TasEligibilityModel from './tas-eligibility.ts';

export interface ApproverInstructionsSignature {
  ordinal: number;
  body: string;
  heading: string;
  [key: string]: unknown;
}

// TODO: the usage of this interface is not consistent with the usage of the
// interface in approver overview
export interface FaqsSignature {
  ordinal?: number;
  heading?: string;
  body?: string;
  answer?: string;
  question?: string;
}

export type TASProgramTemplateModelFieldsSignature = {
  /**
   * ALLOW_PRE_APPROVAL_SUBMISSION_BEYOND_LIMIT
   * @province PROGRAM
   * @description If true, users can submit for pre-approval even if they are outside the annual reimbursement limits
   */
  ALLOW_PRE_APPROVAL_SUBMISSION_BEYOND_LIMIT: BooleanTemplateSingleValueField;

  /**
   * ALLOW_UNACCREDITED_INSTITUTIONS
   * @province PROGRAM
   * @description If true, allows participants to select unaccredited academic institutions
   */
  ALLOW_UNACCREDITED_INSTITUTIONS: BooleanTemplateSingleValueField;

  /**
   * ALLOW_WAIVER_OF_EVIDENCE_APPROVAL
   * @province PROGRAM
   * @description If true, the approver is allowed to optionally waive evidence approval and/or attachment requirements during courses pre-approval
   *              In order to waive evidence approval, approvers must agree to all checklist items within
   *              EVIDENCE_APPROVAL_WAIVER_CHECKLIST and EVIDENCE_ATTACHMENT_WAIVER_CHECKLIST
   */
  ALLOW_WAIVER_OF_EVIDENCE_APPROVAL: BooleanTemplateSingleValueField;

  /**
   * APPLICATION_APPROVE_COURSES_WORKFLOW
   * @province PROGRAM
   * @description The approval workflow for approve_courses for an application (pre-approval)
   */
  APPLICATION_APPROVE_COURSES_WORKFLOW: ApprovalWorkflowTemplateField;

  /**
   * APPLICATION_APPROVE_EVIDENCE_WORKFLOW
   * @province PROGRAM
   * @description The approval workflow for approve_evidence for an application
   */
  APPLICATION_APPROVE_EVIDENCE_WORKFLOW: ApprovalWorkflowTemplateField;

  /**
   * APPLICATION_ID
   * @province PROGRAM
   * @description Program instance identifier (TasProgramInstance.id)
   */
  APPLICATION_ID: StringTemplateSingleValueField;

  /**
   * APPLICATION_NAME
   * @province APPLICATION
   * @description Application Name as provided by employee
   */
  APPLICATION_NAME: StringTemplateSingleValueField;

  /**
   * CERTIFICATE_OTHER_PROGRAM
   * @province PROGRAM
   * @description If true, will use the certificate workflow. Single form application and users cannot add more than one course.
   */
  CERTIFICATE_OTHER_PROGRAM: BooleanTemplateSingleValueField;

  /**
   * CALCULATE_REIMBURSEMENT_BY_CLAIMS_FINANCIALS
   * @province PROGRAM
   * @description If true, the claims financials approver questionnaire is used to calculate the reimbursement amount
   */
  CALCULATE_REIMBURSEMENT_BY_CLAIMS_FINANCIALS: BooleanTemplateSingleValueField;

  /**
   * CALCULATE_TOWARDS_TOTAL_BASED_ON
   * @province LIMIT
   * @description Calculate towards annual limit based on
   * @enumFlavor TAS.Date
   */
  CALCULATE_TOWARDS_TOTAL_BASED_ON: StringTemplateSingleValueField;

  /**
   * CLAIMS_FINANCIALS
   * @province APPLICATION
   * @description If claims financials are used for reimbursement, this field is used to store the claims financials numbers
   */
  CLAIMS_FINANCIALS: ClaimsFinancialsTemplateSingleValueField;

  /**
   * CLOSE_PROGRAM_BASED_ON
   * @province PROGRAM
   * @description Program closes on event
   * @enumFlavor TAS.Event
   */
  CLOSE_PROGRAM_BASED_ON: StringTemplateSingleValueField;

  /**
   * COMPLETION_DOCUMENTS_ATTACHMENT_FIELD
   * @province COURSE
   * @description Array of strings shown as bullet points for required/recommended attachments for evidence approval
   */
  COMPLETION_DOCUMENTS_ATTACHMENT_FIELD: StringTemplateMultipleField;

  /**
   * COMPLETION_DOCUMENTS_STRING
   * @province COURSE
   * @description Instructions for which documents to attach for evidence approval. Displays above COMPLETION_DOCUMENTS_ATTACHMENT_FIELD list.
   */
  COMPLETION_DOCUMENTS_STRING: StringTemplateSingleValueField;

  /**
   * COURSES_APPROVAL_APPROVE_TEXT
   * @province APPLICATION
   * @description Text for auto-populating the approver comments when an approver selects 'Approve' during courses pre-approval
   */
  COURSES_APPROVAL_APPROVE_TEXT: StringTemplateSingleValueField;

  /**
   * COURSES_APPROVAL_REJECT_TEXT
   * @province APPLICATION
   * @description Text for auto-populating the approver comments when an approver selects 'Reject' during courses pre-approval
   */
  COURSES_APPROVAL_REJECT_TEXT: StringTemplateSingleValueField;

  /**
   * COURSES_APPROVAL_REVISE_TEXT
   * @province APPLICATION
   * @description Text for auto-populating the approver comments when an approver selects 'Request Changes' during courses pre-approval
   */
  COURSES_APPROVAL_REVISE_TEXT: StringTemplateSingleValueField;

  /**
   * COURSE_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD
   * @province COURSE
   * @description Array of strings shown as bullet points for required/recommended attachments for course pre-approval
   */
  COURSE_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD: StringTemplateMultipleField;

  /**
   * COURSE_APPROVAL_DOCUMENTS_STRING
   * @province COURSE
   * @description Instructions for which documents to attach for course pre-approval. Displays above COURSE_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD list.
   */
  COURSE_APPROVAL_DOCUMENTS_STRING: StringTemplateSingleValueField;

  /**
   * COURSE_BOOKS
   * @province COURSE
   * @description The cost of course books (used to calculate the total cost of a course)
   */
  COURSE_BOOKS: NumberTemplateSingleValueField;

  /**
   * COURSE_BOOKS_REIMBURSEMENT
   * @province COURSE
   * @description This is not currently in use. It is a limit on reimbursement for course books.
   */
  COURSE_BOOKS_REIMBURSEMENT: NumberTemplateSingleValueField;

  /**
   * COURSE_BOOKS_REIMBURSEMENT_BY_EMPLOYMENT_TYPE
   * @province LIMIT
   * @description This is not currently in use. It is a limit on reimbursement for course books by employment type.
   */
  COURSE_BOOKS_REIMBURSEMENT_BY_EMPLOYMENT_TYPE: ObjectTemplateSingleValueField;

  /**
   * COURSE_CREDIT
   * @province COURSE
   * @description The amount of credits for a course
   */
  COURSE_CREDIT: StringTemplateSingleValueField;

  /**
   * COURSE_DESCRIPTION
   * @province COURSE
   * @description Course Description
   */
  COURSE_DESCRIPTION: StringTemplateSingleValueField;

  /**
   * COURSE_FEES
   * @province COURSE
   * @description The cost of course fees (used to calculate the total cost of a course)
   */
  COURSE_FEES: NumberTemplateSingleValueField;

  /**
   * COURSE_FEE_REIMBURSEMENT
   * @province COURSE
   * @description This is not currently in use. It is a limit on reimbursement for course fees.
   */
  COURSE_FEE_REIMBURSEMENT: NumberTemplateSingleValueField;

  /**
   * COURSE_FEE_REIMBURSEMENT_BY_EMPLOYMENT_TYPE
   * @province COURSE
   * @description This is not currently in use. It is a limit on reimbursement for course fees by employment type.
   */
  COURSE_FEE_REIMBURSEMENT_BY_EMPLOYMENT_TYPE: ObjectTemplateSingleValueField;

  /**
   * COURSE_GRADE
   * @province COURSE
   * @description The grade received for a course
   */
  COURSE_GRADE: StringTemplateSingleValueField;

  /**
   * COURSE_INFORMATION
   * @province COURSE
   * @description Section label for course information
   */
  COURSE_INFORMATION: StringTemplateSingleValueField;

  /**
   * COURSE_LABS
   * @province COURSE
   * @description The cost of course labs (used to calculate the total cost of a course)
   */
  COURSE_LABS: NumberTemplateSingleValueField;

  /**
   * COURSE_LABS_REIMBURSEMENT
   * @province COURSE
   * @description This is not currently in use. It is a limit on reimbursement for course labs.
   */
  COURSE_LABS_REIMBURSEMENT: NumberTemplateSingleValueField;

  /**
   * COURSE_LABS_REIMBURSEMENT_BY_EMPLOYMENT_TYPE
   * @province LIMIT
   * @description This is not currently in use. It is a limit on reimbursement for course labs by employment type.
   */
  COURSE_LABS_REIMBURSEMENT_BY_EMPLOYMENT_TYPE: ObjectTemplateSingleValueField;

  /**
   * COURSE_NAME
   * @province COURSE
   * @description Course Name
   */
  COURSE_NAME: StringTemplateSingleValueField;

  /**
   * COURSE_NUMBER
   * @province COURSE
   * @description Course Number
   */
  COURSE_NUMBER: StringTemplateSingleValueField;

  /**
   * COURSE_SOFTWARE
   * @province COURSE
   * @description The cost of course software (used to calculate the total cost of a course)
   */
  COURSE_SOFTWARE: NumberTemplateSingleValueField;

  /**
   * COURSE_SOFTWARE_REIMBURSEMENT
   * @province COURSE
   * @description This is not currently in use. It is a limit on reimbursement for course software.
   */
  COURSE_SOFTWARE_REIMBURSEMENT: NumberTemplateSingleValueField;

  /**
   * COURSE_SOFTWARE_REIMBURSEMENT_BY_EMPLOYMENT_TYPE
   * @province LIMIT
   * @description This is not currently in use. It is a limit on reimbursement for course software by employment type.
   */
  COURSE_SOFTWARE_REIMBURSEMENT_BY_EMPLOYMENT_TYPE: ObjectTemplateSingleValueField;

  /**
   * COURSE_TUITION
   * @province COURSE
   * @description The cost of course tuition (used to calculate the total cost of a course)
   */
  COURSE_TUITION: NumberTemplateSingleValueField;

  /**
   * COURSE_TUITION_REIMBURSEMENT
   * @province COURSE
   * @description This is not currently in use. It is a limit on reimbursement for course tuition.
   */
  COURSE_TUITION_REIMBURSEMENT: NumberTemplateSingleValueField;

  /**
   * COURSE_TUITION_REIMBURSEMENT_BY_EMPLOYMENT_TYPE
   * @province LIMIT
   * @description This is not currently in use. It is a limit on reimbursement for course tuition by employment type.
   */
  COURSE_TUITION_REIMBURSEMENT_BY_EMPLOYMENT_TYPE: ObjectTemplateSingleValueField;

  /**
   * COURSES_BEGIN_DATE
   * @province APPLICATION
   * @description The estimated start date for the courses in the application
   */
  COURSES_BEGIN_DATE: StringTemplateSingleValueField;

  /**
   * COURSES_END_DATE
   * @province APPLICATION
   * @description The estimated end date for the courses in the application
   */
  COURSES_END_DATE: StringTemplateSingleValueField;

  /**
   * COURSES_RECEIPT_DATE
   * @province APPLICATION
   * @description This field is used for the date of prepayment when a user has paid for the course upfront
   */
  COURSES_RECEIPT_DATE: StringTemplateSingleValueField;

  /**
   * COURSES_PER_YEAR
   * @province LIMIT
   * @description The total allowed number of annual courses
   */
  COURSES_PER_YEAR: NumberTemplateSingleValueField;

  /**
   * CREATE_SIGNATURE_FLOW
   * @province PROGRAM
   * @description This is not currently in use. It is related to requiring a signature for submitting an application.
   */
  CREATE_SIGNATURE_FLOW: BooleanTemplateSingleValueField;

  /**
   * DEPENDENT_PROGRAM
   * @province PROGRAM
   * @description If true, the program is for a dependent of an employee
   */
  DEPENDENT_PROGRAM: BooleanTemplateSingleValueField;

  /**
   * DEPENDENT_PROGRAM_AGE_LIMITS
   * @province PROGRAM
   * @description If true, the program is for a dependent of an employee
   */
  DEPENDENT_PROGRAM_AGE_LIMITS: ObjectTemplateSingleValueField;

  /**
   * DEPENDENT_NAME
   * @province DEPENDENT
   * @description Full Name
   */
  DEPENDENT_NAME: StringTemplateSingleValueField;

  /**
   * DEPENDENT_DOB
   * @province DEPENDENT
   * @description Birthdate
   */
  DEPENDENT_DOB: StringTemplateSingleValueField;

  /**
   * DEPENDENT_RELATIONSHIP
   * @province DEPENDENT
   * @description Relationship to the employee "Spouse | Child | Partner"
   */
  DEPENDENT_RELATIONSHIP: StringTemplateSingleValueField;

  /**
   * ELIGIBILITY_WAITING_PERIOD
   * @province PROGRAM
   * @description Lead time (days) required after employment start date to be eligible for program.
   */
  ELIGIBILITY_WAITING_PERIOD: NumberTemplateSingleValueField;

  /**
   * EMPLOYEE_DOB
   * @province APPLICATION
   * @description Birthday
   */
  EMPLOYEE_DOB: StringTemplateSingleValueField;

  /**
   * EMPLOYEE_EMAIL
   * @province APPLICANT
   * @description Email
   */
  EMPLOYEE_EMAIL: StringTemplateSingleValueField;

  /**
   * EMPLOYEE_ID
   * @province APPLICANT
   * @description Employee payroll ID
   */
  EMPLOYEE_ID: StringTemplateSingleValueField;

  /**
   * EMPLOYEE_TITLE
   * @province APPLICANT
   * @description Title
   */
  EMPLOYEE_TITLE: StringTemplateSingleValueField;

  /**
   * ENROLLMENT_OBJECTIVE
   * @province PROGRAM
   * @description Enrollment Objective
   */
  ENROLLMENT_OBJECTIVE: StringTemplateSingleValueField;

  /**
   * ESTIMATED_COST
   * @province PROGRAM
   * @description Estimated Cost
   */
  ESTIMATED_COST: NumberTemplateSingleValueField;

  /**
   * ESTIMATED_DAYS_TO_PAID
   * @province PROGRAM
   * @description The number of days to add as a buffer for determining estimated paid/prepaid date for applications (limits calculations)
   */
  ESTIMATED_DAYS_TO_PAID: NumberTemplateSingleValueField;

  /**
   * ESTIMATED_PROGRAM_BEGIN
   * @province PROGRAM
   * @description Estimated Program Begin Date
   */
  ESTIMATED_PROGRAM_BEGIN: StringTemplateSingleValueField;

  /**
   * ESTIMATED_PROGRAM_BEGIN
   * @province PROGRAM
   * @description Estimated Program Completion Date
   */
  ESTIMATED_PROGRAM_COMPLETION: StringTemplateSingleValueField;

  /**
   * EVIDENCE_APPROVAL_APPROVE_TEXT
   * @province APPLICATION
   * @description Text for auto-populating the approver comments when an approver selects 'Approve' during courses pre-approval
   */
  EVIDENCE_APPROVAL_APPROVE_TEXT: StringTemplateSingleValueField;

  /**
   * EVIDENCE_APPROVAL_REJECT_TEXT
   * @province APPLICATION
   * @description Text for auto-populating the approver comments when an approver selects 'Reject' during courses pre-approval
   */
  EVIDENCE_APPROVAL_REJECT_TEXT: StringTemplateSingleValueField;

  /**
   * EVIDENCE_APPROVAL_REVISE_TEXT
   * @province APPLICATION
   * @description Text for auto-populating the approver comments when an approver selects 'Request Changes' during courses pre-approval
   */
  EVIDENCE_APPROVAL_REVISE_TEXT: StringTemplateSingleValueField;

  /**
   * EVIDENCE_APPROVAL_WAIVER_CHECKLIST
   * @province PROGRAM
   * @description An array of strings to be used as a checklist for the approver to confirm before waiving evidence approval.
   *              To waive evidence approval, the approver must confirm all of these items in addition to the items in the evidence attachment waiver checklist.
   */
  EVIDENCE_APPROVAL_WAIVER_CHECKLIST: StringTemplateMultipleField;

  /**
   * EVIDENCE_ATTACHMENT_WAIVER_CHECKLIST
   * @province PROGRAM
   * @description An array of strings to be used as a checklist for the approver to confirm before waiving evidence attachment requirements.
   *              To waive evidence attachment requirements, the approver must confirm all of these items.
   */
  EVIDENCE_ATTACHMENT_WAIVER_CHECKLIST: StringTemplateMultipleField;

  /**
   * EXCLUSIVE_ACADEMIC_PERFORMANCE_OPTIONS
   * @province PROGRAM
   * @description An array of enums by name with flavor AcademicPerformance to be used for COURSE_GRADE options for the program. If empty, all AcademicPerformance enums will be used as options.
   * @enumFlavor AcademicPerformance
   */
  EXCLUSIVE_ACADEMIC_PERFORMANCE_OPTIONS: StringTemplateMultipleField;

  /**
   * EXPENSE_TYPE
   * @province APPLICATION
   * @description All expense types for employees to classify their reimbursements.
   */
  EXPENSE_TYPE: StringTemplateSingleValueField;

  /**
   * FINAL_APPROVED_TOTAL
   * @province APPLICATION
   * @description Final approved total
   */
  FINAL_APPROVED_TOTAL: NumberTemplateSingleValueField;

  /**
   * FT_PT_OTHER
   * @province APPLICANT
   * @description Employee Status
   * @enumFlavor EmploymentType
   */
  FT_PT_OTHER: StringTemplateSingleValueField;

  /**
   * GRADE_POINT_AVERAGE
   * @province APPLICANT
   * @description Grade Point Average
   */
  GRADE_POINT_AVERAGE: NumberTemplateSingleValueField;

  /**
   * HIRE_DATE
   * @province APPLICANT
   * @description Hire Date
   */
  HIRE_DATE: StringTemplateSingleValueField;

  /**
   * INSTITUTION_LIST_IS_EXCLUSIVE
   * @province PROGRAM
   * @description If true, only allow partner-provided schools for selection
   */
  INSTITUTION_LIST_IS_EXCLUSIVE: BooleanTemplateSingleValueField;

  /**
   * INVOICE_DATE
   * @province APPLICATION
   * @description If true, allow approvers to input an invoice date for application approval
   */
  INVOICE_DATE: BooleanTemplateSingleValueField;

  /**
   * LOCK_BALANCE_MULTIPLIER_AT_START_OF_FISCAL_YEAR
   * @province PROGRAM
   * @description If true, balance multiplier for an employee is locked at the start of the partner's fiscal year
   */
  LOCK_BALANCE_MULTIPLIER_AT_START_OF_FISCAL_YEAR: BooleanTemplateSingleValueField;

  /**
   * LOCK_EMPLOYEE_STATUS_BASED_ON
   * @province LIMIT
   * @description Lock employee status based on
   * @enumFlavor TAS.EmployeeStatusLockEvent
   */
  LOCK_EMPLOYEE_STATUS_BASED_ON: StringTemplateSingleValueField;

  /**
   * MAXIMUM_ANNUAL_REIMBURSEMENT
   * @province PROGRAM
   * @description Annual limit for employees, regardless of employment type
   */
  MAXIMUM_ANNUAL_REIMBURSEMENT: NumberTemplateSingleValueField;

  /**
   * MAXIMUM_ANNUAL_REIMBURSEMENT_BY_EMPLOYMENT_TYPE
   * @province PROGRAM
   * @description Annual limit for employees by employment type
   */
  MAXIMUM_ANNUAL_REIMBURSEMENT_BY_EMPLOYMENT_TYPE: ObjectTemplateSingleValueField;

  /**
   * MAXIMUM_LIFETIME_REIMBURSEMENT
   * @province PROGRAM
   * @description Program lifetime limit for employees, regardless of employment type
   */
  MAXIMUM_LIFETIME_REIMBURSEMENT: NumberTemplateSingleValueField;

  /**
   * MAXIMUM_LIFETIME_REIMBURSEMENT_BY_EMPLOYMENT_TYPE
   * @province PROGRAM
   * @description Program lifetime limit for employees by employment type
   */
  MAXIMUM_LIFETIME_REIMBURSEMENT_BY_EMPLOYMENT_TYPE: ObjectTemplateSingleValueField;

  /**
   * MULTIPLE_INSTANCE_PROGRAM
   * @province PROGRAM
   * @description Allows for multiple of the same program instance
   */
  MULTIPLE_INSTANCE_PROGRAM: BooleanTemplateSingleValueField;

  /**
   * NAME
   * @province APPLICANT
   * @description Employee full name
   */
  NAME: StringTemplateSingleValueField;

  /**
   * PASSING_ACADEMIC_PERFORMANCES
   * @province PROGRAM
   * @description An array of enums by name with flavor AcademicPerformance that are considered 'passing' grades for a course. Only relevant for programs that make calculations based on passing grades.
   * @enumFlavor AcademicPerformance
   */
  PASSING_ACADEMIC_PERFORMANCES: StringTemplateMultipleField;

  /**
   * PAYMENT_SUBMISSION_MAX_DAYS_POST_COMPLETION
   * @province PROGRAM
   * @description Partner requirement for maximum allowable days for payment submission past course completion.
   */
  PAYMENT_SUBMISSION_MAX_DAYS_POST_COMPLETION: NumberTemplateSingleValueField;

  /**
   * PAYMENT_SUBMISSION_MIN_DAYS_BEFORE_YEAR_END
   * @province PROGRAM
   * @description The number of days to subtract from current fiscal year end to determine payment submission deadline date
   */
  PAYMENT_SUBMISSION_MIN_DAYS_BEFORE_YEAR_END: NumberTemplateSingleValueField;

  /**
   * PAYROLL_EXPECTATION
   * @province PROGRAM
   * @description Description representing setting payroll expectation with employee upon application submission/approval
   */
  PAYROLL_EXPECTATION: StringTemplateSingleValueField;

  /**
   * PERFORMANCE_RATING
   * @province APPLICANT
   * @description Employee's performance rating
   */
  PERFORMANCE_RATING: StringTemplateSingleValueField;

  /**
   * POST_COURSE_END_EVIDENCE_SUBMISSION_MAX_LAG_TIME
   * @province PROGRAM
   * @description Allowed number of days post course end date to allow application evidence submission
   */
  POST_COURSE_END_EVIDENCE_SUBMISSION_MAX_LAG_TIME: NumberTemplateSingleValueField;

  /**
   * PRE_APPROVED_TOTAL
   * @province APPLICATION
   * @description Pre-approved total
   */
  PRE_APPROVED_TOTAL: NumberTemplateSingleValueField;

  /**
   * PRE_APPROVAL_TO_REGISTRATION_MAX_LEAD_TIME
   * @province PROGRAM
   * @description Partner requirement for allowed max lead time (in days) for application submission prior to registration
   */
  PRE_APPROVAL_TO_REGISTRATION_MAX_LEAD_TIME: NumberTemplateSingleValueField;

  /**
   * PRE_APPROVAL_TO_REGISTRATION_MIN_LEAD_TIME
   * @province PROGRAM
   * @description Partner requirement for allowed minimum lead time (in days) for application submission prior to registration
   */
  PRE_APPROVAL_TO_REGISTRATION_MIN_LEAD_TIME: NumberTemplateSingleValueField;

  /**
   * PRE_CALC_REQUESTED_TOTAL_BY_CREDIT
   * @province PROGRAM
   * @description If true, course credit will be used to multiply by a set repayment value per credit upon application submission
   */
  PRE_CALC_REQUESTED_TOTAL_BY_CREDIT: BooleanTemplateSingleValueField;

  /**
   * PRE_CALC_REQUESTED_TOTAL_BY_GPA
   * @province PROGRAM
   * @description If true, application will set repayment to the lesser of the requested total or the GPA set repayment value
   */
  PRE_CALC_REQUESTED_TOTAL_BY_GPA: BooleanTemplateSingleValueField;

  /**
   * PRE_CALC_REQUESTED_TOTAL_BY_PERCENT
   * @province PROGRAM
   * @description If true, REIMBURSEMENT_PERCENTAGE will be used to calculate the requested total upon application submission
   */
  PRE_CALC_REQUESTED_TOTAL_BY_PERCENT: BooleanTemplateSingleValueField;

  /**
   * PROGRAM_APPROVAL_APPROVE_TEXT
   * @province PROGRAM
   * @description Text for auto-populating the approver comments when an approver selects 'Approve' during courses pre-approval
   */
  PROGRAM_APPROVAL_APPROVE_TEXT: StringTemplateSingleValueField;

  /**
   * PROGRAM_APPROVAL_REJECT_TEXT
   * @province PROGRAM
   * @description Text for auto-populating the approver comments when an approver selects 'Reject' during courses pre-approval
   */
  PROGRAM_APPROVAL_REJECT_TEXT: StringTemplateSingleValueField;

  /**
   * PROGRAM_APPROVAL_REVISE_TEXT
   * @province PROGRAM
   * @description Text for auto-populating the approver comments when an approver selects 'Request Changes' during courses pre-approval
   */
  PROGRAM_APPROVAL_REVISE_TEXT: StringTemplateSingleValueField;

  /**
   * PROGRAM_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD
   * @province PROGRAM
   * @description Array of strings shown as bullet points for required/recommended attachments for program pre-approval
   */
  PROGRAM_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD: StringTemplateMultipleField;

  /**
   * PROGRAM_APPROVAL_DOCUMENTS_STRING
   * @province PROGRAM
   * @description Instructions for which documents to attach for program pre-approval. Displays above PROGRAM_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD list.
   */
  PROGRAM_APPROVAL_DOCUMENTS_STRING: StringTemplateSingleValueField;

  /**
   * PROGRAM_APPROVE_WITHDRAW_COMPLETE_WORKFLOW
   * @province PROGRAM
   * @description The approval workflow for approve_completion/approve_withdraw for a program instance
   */
  PROGRAM_APPROVE_WITHDRAW_COMPLETE_WORKFLOW: ApprovalWorkflowTemplateField;

  /**
   * PROGRAM_APPROVE_WORKFLOW
   * @province PROGRAM
   * @description The approval workflow for approve_program for a program instance (program pre-approval)
   */
  PROGRAM_APPROVE_WORKFLOW: ApprovalWorkflowTemplateField;

  /**
   * PROGRAM_CODE
   * @province PROGRAM
   * @description Program Code (2 character code)
   */
  PROGRAM_CODE: StringTemplateSingleValueField;

  /**
   * PROGRAM_DESCRIPTION
   * @province PROGRAM
   * @description Program Description
   */
  PROGRAM_DESCRIPTION: StringTemplateSingleValueField;

  /**
   * PROGRAM_MAJOR
   * @province PROGRAM
   * @description The program major (ex. Computer Science)
   */
  PROGRAM_MAJOR: StringTemplateSingleValueField;

  /**
   * PROGRAM_NAME
   * @province PROGRAM
   * @description The program name
   */
  PROGRAM_NAME: StringTemplateSingleValueField;

  /**
   * PROGRAM_PAYMENT
   * @province PROGRAM
   * @description When assistance is advised.  ON_ATTEND for Pre-paid.  ON_FULFILLED for Reimbursement.
   */
  PROGRAM_PAYMENT: StringTemplateSingleValueField;

  /**
   * PROGRAM_SPECIALIZATION
   * @province PROGRAM
   * @description Specific specialization, degree or certificate name
   */
  PROGRAM_SPECIALIZATION: StringTemplateSingleValueField;

  /**
   * PROGRAM_TYPE
   * @province PROGRAM
   * @description Type of program e.g. Degree
   */
  PROGRAM_TYPE: StringTemplateSingleValueField;

  /**
   * REGISTRATION_TO_PRE_APPROVAL_MAX_LAG_TIME
   * @province PROGRAM
   * @description Allowed number of days post course start date to allow application submission
   */
  REGISTRATION_TO_PRE_APPROVAL_MAX_LAG_TIME: NumberTemplateSingleValueField;

  /**
   * REIMBURSEMENT_PER_COURSE_CREDIT
   * @province PROGRAM
   * @description When PRE_CALC_REQUESTED_TOTAL_BY_CREDIT is true, this number is used to pre-calculate the requested application total by multiplying it by the course credit amount
   */
  REIMBURSEMENT_PER_COURSE_CREDIT: NumberTemplateSingleValueField;

  /**
   * REIMBURSEMENT_PERCENTAGE
   * @province PROGRAM
   * @description When PRE_CALC_REQUESTED_TOTAL_BY_PERCENT is true, this number is used to pre-calculate the requested application total by multiplying it by the total amount requested in the application.
   *              Number is an integer representing a percentage with a max of 100 (ex. 80 for 80%)
   */
  REIMBURSEMENT_PERCENTAGE: NumberTemplateSingleValueField;

  /**
   * REIMBURSEMENT_PERCENT_BY_ACADEMIC_PERFORMANCE
   * @province PROGRAM
   * @description The reimbursement percentage to apply based on COURSE_GRADE.
   * @enumFlavor AcademicPerformance
   */
  REIMBURSEMENT_PERCENT_BY_ACADEMIC_PERFORMANCE: ObjectTemplateSingleValueField;

  /**
   * REIMBURSEMENT_PERCENTAGE_FOR_CLAIMS_FINANCIALS
   * @province PROGRAM
   * @description When CALCULATE_REIMBURSEMENT_BY_CLAIMS_FINANCIALS is true, this number divided by 100 will be used as CF_BENEFIT_FRACTIONAL_PERCENT_APPLIED
   */
  REIMBURSEMENT_PERCENTAGE_FOR_CLAIMS_FINANCIALS: NumberTemplateSingleValueField;

  /**
   * REMOVE_PROCESS_DESCRIPTION_STEPS
   * @province PROGRAM
   * @description If false, the section 'What is the Process' will be displayed on the program-overview page
   */
  REMOVE_PROCESS_DESCRIPTION_STEPS: BooleanTemplateSingleValueField;

  /**
   * SCHOLARSHIP_AMOUNT
   * @province SCHOLARSHIP
   * @description Scholarship Amount
   */
  SCHOLARSHIP_AMOUNT: NumberTemplateSingleValueField;

  /**
   * SCHOLARSHIP_NAME
   * @province SCHOLARSHIP
   * @description Scholarship Name
   */
  SCHOLARSHIP_NAME: StringTemplateSingleValueField;

  /**
   * SCHOLARSHIPS_RECEIVED
   * @province APPLICATION
   * @description An array of scholarships received for an application
   */
  SCHOLARSHIPS_RECEIVED: ScholarshipsReceivedTemplateField;

  /**
   * SCHOOL_INSTITUTION_NAME
   * @province PROGRAM
   * @description Institution Name
   */
  SCHOOL_INSTITUTION_NAME: StringTemplateSingleValueField;

  /**
   * SEMESTER_CODE
   * @province APPLICATION
   * @description The semester code is based on courses begin date
   */
  SEMESTER_CODE: StringTemplateSingleValueField;

  /**
   * SINGLE_APPLICATION_PROGRAM
   * @province PROGRAM
   * @description If the program only allows for a single courses application during its lifespan
   */
  SINGLE_APPLICATION_PROGRAM: BooleanTemplateSingleValueField;

  /**
   * SUSPENDED_PROGRAM_DISCLOSURE
   * @province PROGRAM
   * @description The text displayed with the Apply For Courses button if the program template is suspended
   */
  SUSPENDED_PROGRAM_DISCLOSURE: StringTemplateSingleValueField;

  /**
   * STUDENT_ID
   * @province APPLICATION
   * @description Identifier provided by education institution
   */
  STUDENT_ID: StringTemplateSingleValueField;

  /**
   * TRACKING_ONLY_PROGRAM
   * @province PROGRAM
   * @description If true, no reimbursement needed for employee
   */
  TRACKING_ONLY_PROGRAM: BooleanTemplateSingleValueField;

  /**
   * TYPE
   * @province PROGRAM
   * @description Each different type leads to a different experience
   */
  TYPE: StringTemplateSingleValueField;

  /**
   * USE_BALANCE_MULTIPLIER_FOR_PROGRAM_LIMITS
   * @province PROGRAM
   * @description If true, program limits for each employee are to be determined by a percentage multiplier in the eligibility file
   */
  USE_BALANCE_MULTIPLIER_FOR_PROGRAM_LIMITS: BooleanTemplateSingleValueField;

  /**
   * VOUCHER_AMOUNT
   * @province APPLICATION
   * @description Amount of the voucher, as provided by the available amount from the limits status call
   */
  VOUCHER_AMOUNT: NumberTemplateSingleValueField;

  /**
   * VOUCHER_PROGRAM
   * @province PROGRAM
   * @description If true, will be used as a voucher program
   */
  VOUCHER_PROGRAM: BooleanTemplateSingleValueField;

  /**
   * VOUCHER_FILENAME
   * @province PROGRAM
   * @description PDF Filename to be generated for voucher program
   */
  VOUCHER_FILENAME: StringTemplateSingleValueField;

  /**
   * WAIVE_EVIDENCE_ATTACHMENT_REQUIREMENT
   * @province APPLICATION
   * @description If true, the requirement config for COMPLETION_DOCUMENTS_ATTACHMENT_FIELD is overridden and the applicant can submit for evidence approval without attachments
   */
  WAIVE_EVIDENCE_ATTACHMENT_REQUIREMENT: BooleanTemplateSingleValueField;

  /**
   * WAIVE_EVIDENCE_APPROVAL_REQUIREMENT
   * @province APPLICATION
   * @description If true, the APPLICATION_APPROVE_EVIDENCE_WORKFLOW is overridden and the application skips final evidence approval
   */
  WAIVE_EVIDENCE_APPROVAL_REQUIREMENT: BooleanTemplateSingleValueField;

  /**
   * WEEKDAY_FOR_APPLICATION_REVIEW
   * @province PROGRAM
   * @description Specific day of the week applications are reviewed
   */
  WEEKDAY_FOR_APPLICATION_REVIEW: StringTemplateSingleValueField;

  /**
   * WITHDRAW_COMPLETE_APPROVAL_APPROVE_TEXT
   * @province PROGRAM
   * @description Text for auto-populating the approver comments when an approver selects 'Approve' during courses pre-approval
   */
  WITHDRAW_COMPLETE_APPROVAL_APPROVE_TEXT: StringTemplateSingleValueField;

  /**
   * WITHDRAW_COMPLETE_APPROVAL_REJECT_TEXT
   * @province PROGRAM
   * @description Text for auto-populating the approver comments when an approver selects 'Reject' during courses pre-approval
   */
  WITHDRAW_COMPLETE_APPROVAL_REJECT_TEXT: StringTemplateSingleValueField;

  /**
   * WITHDRAW_COMPLETE_APPROVAL_REVISE_TEXT
   * @province PROGRAM
   * @description Text for auto-populating the approver comments when an approver selects 'Request Changes' during courses pre-approval
   */
  WITHDRAW_COMPLETE_APPROVAL_REVISE_TEXT: StringTemplateSingleValueField;

  /**
   * YEAR_BEGINS_ON
   * @province LIMIT
   * @description Month that the year begins with, default is January
   */
  YEAR_BEGINS_ON: StringTemplateSingleValueField;

  /**
   * YOUR_INFORMATION
   * @province APPLICANT
   * @description Section label for employee information
   */
  YOUR_INFORMATION: StringTemplateSingleValueField;
};

export default class TASProgramTemplateModel extends Model {
  @attr declare code: string;
  @attr declare state: TasProgramTemplateState;
  @attr declare fields: TASProgramTemplateModelFieldsSignature;
  @attr declare customFields: CustomFieldSignature[];
  @attr declare faqs: FaqsSignature[];
  @attr declare approverInstructions: ApproverInstructionsSignature[];
  @attr declare isTrial: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: 'tasProgramTemplates' })
  declare company: CompanyModel;
  @hasMany('condition', {
    async: false,
    inverse: 'conditionable',
    as: 'conditionable',
  })
  declare conditions: ConditionModel[];
  @hasMany('tas-program-instance', {
    async: false,
    inverse: 'tasProgramTemplate',
  })
  declare tasProgramInstances: TASProgramInstanceModel[];
  @hasMany('tas-field-option', { async: false, inverse: 'tasProgramTemplate' })
  declare tasFieldOptions: TasFieldOptionModel[];
  @hasMany('tas-group', { async: false, inverse: 'tasProgramTemplate' })
  declare tasGroups: TasGroupModel[];
  @hasMany('tas-grouping', { async: false, inverse: 'tasProgramTemplate' })
  declare tasGroupings: TasGroupingModel[];
  @hasMany('tas-program-template-version', { async: false, inverse: null })
  declare versions: TasProgramTemplateVersionModel[];
  @hasMany('tas-eligibility', { async: false, inverse: 'tasProgramTemplate' })
  declare tasEligibilities: TasEligibilityModel[];

  /**************************
   **  Computed Properties **
   **************************/

  // Ember Data doesn't support "multiple foreign keys".
  // For more information about why these are setup as cached getters, see:
  // https://github.com/emberjs/data/issues/8392

  @cached
  get programApproveConditions() {
    return Object.freeze(
      this.conditions.filter((condition) => condition.province === 'program_approve_workflow')
    );
  }

  @cached
  get programApproveWithdrawCompleteConditions() {
    return Object.freeze(
      this.conditions.filter(
        (condition) => condition.province === 'program_approve_withdraw_complete_workflow'
      )
    );
  }

  @cached
  get applicationApproveCoursesConditions() {
    return Object.freeze(
      this.conditions.filter(
        (condition) => condition.province === 'application_approve_courses_workflow'
      )
    );
  }

  @cached
  get applicationApproveEvidenceConditions() {
    return Object.freeze(
      this.conditions.filter(
        (condition) => condition.province === 'application_approve_evidence_workflow'
      )
    );
  }

  @cached
  get typeTwoApplicationConditions() {
    if (
      this.typeClassification === 'TAS.ProgramType.2' ||
      this.typeClassification === 'TAS.ProgramType.4.2'
    ) {
      return Object.freeze([
        ...this.applicationApproveCoursesConditions,
        ...this.applicationApproveEvidenceConditions,
      ]);
    } else return this.applicationApproveCoursesConditions;
  }

  @cached
  get fieldEntries() {
    const fieldsHash = this.fields || {};
    return Object.entries(fieldsHash);
  }

  // NOTE: Instance fields are set on the tas-program-instance model as they tie directly into info from the employee model

  // Returns an object to be used for the initial values for a new TasApplication's fields object
  @cached
  get applicationFieldsHash() {
    const applicationEntries = this.fieldEntries
      .filter((entry) => {
        return entry[1]?.province === 'APPLICATION';
      })
      .map((entry) => {
        return [entry[0], { values: entry[1]?.values || [] }];
      });
    return Object.fromEntries(applicationEntries);
  }

  @cached
  get courseFieldsHash() {
    const excludedFields = new Set([
      'COURSE_APPROVAL_DOCUMENTS_STRING',
      'COMPLETION_DOCUMENTS_ATTACHMENT_FIELD',
      'COURSE_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD',
      'COMPLETION_DOCUMENTS_STRING',
      'COURSE_INFORMATION',
      'APPROVED_COURSE_TOTAL',
    ]);

    const courseApplicantEntries = this.fieldEntries
      .filter((entry) => {
        return entry[1]?.province === 'COURSE' && !excludedFields.has(entry[0]);
      })
      .map((entry) => {
        return [entry[0], { values: entry[1]?.values || [] }];
      });

    return Object.fromEntries(courseApplicantEntries);
  }

  get instanceCustomFields() {
    const fields = this.customFields || [];
    return fields.filter((field) => ['PROGRAM', 'EMPLOYEE'].includes(field.province));
  }

  get applicationCustomFields() {
    const fields = this.customFields || [];
    return fields.filter((field) => field.province === 'APPLICATION');
  }

  get courseCustomFields() {
    const fields = this.customFields || [];
    return fields.filter((field) => field.province === 'COURSE');
  }

  get programName() {
    return this.fields?.['PROGRAM_NAME']?.values?.[0] || '';
  }

  get programDescription() {
    return this.fields?.['PROGRAM_DESCRIPTION']?.values?.[0] || '';
  }

  get programApproverInstructions() {
    const instructions = this.approverInstructions || [];
    return instructions.sort((a, b) => a.ordinal - b.ordinal);
  }

  get programFaqs() {
    const faqs = this.faqs || [];
    // @ts-expect-error: this is a hack to get around the fact that the faqs
    // aren't correctly typed. They seem to mix content with presentation.
    return faqs.sort((a, b) => a.ordinal - b.ordinal);
  }

  get programPaymentType() {
    return this.fields?.['PROGRAM_PAYMENT']?.values?.[0] || '';
  }

  get isPrepaidProgram() {
    return this.programPaymentType === 'ON_ATTEND';
  }

  get isReimbursementProgram() {
    return this.programPaymentType === 'ON_FULFILLED';
  }

  get isPreApprovalEnabled() {
    return !!this.fields?.['PROGRAM_APPROVE_WORKFLOW']?.required;
  }

  get isCoursePreApprovalEnabled() {
    return !!this.fields?.['APPLICATION_APPROVE_COURSES_WORKFLOW']?.required;
  }

  get isMultipleInstanceProgram() {
    return this.fields?.['MULTIPLE_INSTANCE_PROGRAM']?.values?.[0] || false;
  }

  get isTrackingOnlyProgram() {
    return this.fields?.['TRACKING_ONLY_PROGRAM']?.values?.[0] || false;
  }

  get isCertificateProgram() {
    return this.fields?.['CERTIFICATE_OTHER_PROGRAM']?.values?.[0] || false;
  }

  get annualLimitsByEmploymentType() {
    return this.fields?.['MAXIMUM_ANNUAL_REIMBURSEMENT_BY_EMPLOYMENT_TYPE']?.values?.[0] || {};
  }

  get annualLimitForAllEmploymentTypes() {
    return this.fields?.['MAXIMUM_ANNUAL_REIMBURSEMENT']?.values?.[0];
  }

  get calculateTotalByCredit() {
    return this.fields?.['PRE_CALC_REQUESTED_TOTAL_BY_CREDIT']?.values?.[0] || false;
  }

  get ratePerCredit() {
    return this.fields?.['REIMBURSEMENT_PER_COURSE_CREDIT']?.values?.[0];
  }

  get calculateTotalByPercent() {
    return this.fields?.['PRE_CALC_REQUESTED_TOTAL_BY_PERCENT']?.values?.[0] || false;
  }

  get reimbursementPercentage() {
    return this.fields?.['REIMBURSEMENT_PERCENTAGE']?.values?.[0] || 100;
  }

  get calculateTotalByClaimsFinancials() {
    return this.fields?.['CALCULATE_REIMBURSEMENT_BY_CLAIMS_FINANCIALS']?.values?.[0] || false;
  }

  get calculateTotalByGPA() {
    return this.fields?.['PRE_CALC_REQUESTED_TOTAL_BY_GPA']?.values?.[0] || false;
  }

  get passingGrades() {
    return this.fields?.['PASSING_ACADEMIC_PERFORMANCES']?.values || [];
  }

  get hideProcessDescriptionSteps() {
    return this.fields?.['REMOVE_PROCESS_DESCRIPTION_STEPS']?.values[0] || false;
  }

  // TODO: Refactor this or consuming code because returning two types is confusing.
  get annualLimitValue() {
    const checkEmploymentTypesForLength =
      Object.keys(this.annualLimitsByEmploymentType).length === 0;
    if (checkEmploymentTypesForLength) {
      return this.annualLimitForAllEmploymentTypes;
    } else {
      return this.annualLimitsByEmploymentType;
    }
  }

  get lifetimeLimitForAllEmploymentTypes() {
    const allValue = this.fields?.['MAXIMUM_LIFETIME_REIMBURSEMENT']?.values?.[0];

    return allValue ? { all: allValue } : {};
  }

  get lifetimeLimitsByEmploymentType() {
    return this.fields?.['MAXIMUM_LIFETIME_REIMBURSEMENT_BY_EMPLOYMENT_TYPE']?.values?.[0] || {};
  }

  get lifetimeLimitValue() {
    return this.lifetimeLimitsByEmploymentType || this.lifetimeLimitForAllEmploymentTypes;
  }

  get allowPreApprovalSubmissionBeyondLimit() {
    return this.fields?.['ALLOW_PRE_APPROVAL_SUBMISSION_BEYOND_LIMIT']?.values?.[0] || false;
  }

  get requireAttachmentsForCourseApproval() {
    return !!this.fields?.['COURSE_APPROVAL_DOCUMENTS_STRING']?.required;
  }

  get attachmentsForCourseApprovalVisible() {
    return !!this.fields?.['COURSE_APPROVAL_DOCUMENTS_STRING']?.visible;
  }

  get requireAttachmentsForCourseCompletion() {
    return !!this.fields?.['COMPLETION_DOCUMENTS_STRING']?.required;
  }

  get attachmentsForCourseCompletionVisible() {
    return !!this.fields?.['COMPLETION_DOCUMENTS_STRING']?.visible;
  }

  get requireAttachmentsForProgramApproval() {
    return !!this.fields?.['PROGRAM_APPROVAL_DOCUMENTS_STRING']?.required;
  }

  get attachmentsForProgramApprovalVisible() {
    return !!this.fields?.['PROGRAM_APPROVAL_DOCUMENTS_STRING']?.visible;
  }

  get programApprovalRequired() {
    return !!this.fields?.['PROGRAM_APPROVE_WORKFLOW']?.required;
  }

  get courseApprovalRequired() {
    return !!this.fields?.['APPLICATION_APPROVE_COURSES_WORKFLOW']?.required;
  }

  get evidenceApprovalRequired() {
    return (
      !!this.fields?.['APPLICATION_APPROVE_EVIDENCE_WORKFLOW']?.required &&
      !this.fields?.['WAIVE_EVIDENCE_APPROVAL_REQUIREMENT']?.required
    );
  }

  get courseGradeRequired() {
    return !!this.fields?.['COURSE_GRADE']?.required;
  }

  get semesterCodeIsVisible() {
    return !!this.fields?.['SEMESTER_CODE']?.visible || false;
  }

  get scholarshipsAreVisible() {
    return !!this.fields?.['SCHOLARSHIPS_RECEIVED']?.visible;
  }

  get courseReceiptDateVisible() {
    return !!this.fields?.['COURSES_RECEIPT_DATE']?.visible;
  }

  get isLaunched() {
    return this.state === 'TAS.ProgramTemplateState.LAUNCHED';
  }

  get isDraft() {
    return this.state === 'TAS.ProgramTemplateState.DRAFT';
  }

  get fractionalBenefitPercentForClaimsFinancials(): number {
    const rawPercentage =
      (this.fields?.['REIMBURSEMENT_PERCENTAGE_FOR_CLAIMS_FINANCIALS']?.values?.[0] || 100) / 100;
    return parseFloat(rawPercentage.toFixed(2));
  }

  get hasInProgressProgramInstances() {
    // returns instances that have been submitted, but not in terminal state
    return this.tasProgramInstances.some((instance) => instance.programInstanceInProgress);
  }

  get eligibilityWaitingPeriod() {
    return this.fields?.['ELIGIBILITY_WAITING_PERIOD']?.values?.[0] || 0;
  }

  get isDependentProgram() {
    return this.fields?.['DEPENDENT_PROGRAM']?.values?.[0] || false;
  }

  get isVoucherProgram() {
    return this.fields?.['VOUCHER_PROGRAM']?.values?.[0] || false;
  }

  get lastDayToSubmitApplicationForPreApproval() {
    // number of days AFTER course start that you can apply
    return this.fields?.['REGISTRATION_TO_PRE_APPROVAL_MAX_LAG_TIME']?.values?.[0] || '';
  }

  get coursesPreApprovalSubmissionMaxLeadTime() {
    // MAX number of days BEFORE course start that you can apply
    return this.fields?.['PRE_APPROVAL_TO_REGISTRATION_MAX_LEAD_TIME']?.values?.[0] || '';
  }

  get coursesPreApprovalSubmissionMinLeadTime() {
    // MIN number of days BEFORE course start that you can apply
    return this.fields?.['PRE_APPROVAL_TO_REGISTRATION_MIN_LEAD_TIME']?.values?.[0] || '';
  }

  get typeClassification() {
    return this.fields?.['TYPE']?.values?.[0] || '';
  }

  get lockEmployeeStatusBasedOn() {
    return this.fields?.['LOCK_EMPLOYEE_STATUS_BASED_ON']?.values?.[0] || '';
  }

  get attachmentListItems() {
    return {
      programApproval: this.fields?.['PROGRAM_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD']?.values || [],
      coursePreApproval: this.fields?.['COURSE_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD']?.values || [],
      courseCompletion: this.fields?.['COMPLETION_DOCUMENTS_ATTACHMENT_FIELD']?.values || [],
    };
  }

  get attachmentInstructions() {
    return {
      programApproval: this.fields?.['PROGRAM_APPROVAL_DOCUMENTS_STRING']?.values?.[0] || '',
      coursePreApproval: this.fields?.['COURSE_APPROVAL_DOCUMENTS_STRING']?.values?.[0] || '',
      courseCompletion: this.fields?.['COMPLETION_DOCUMENTS_STRING']?.values?.[0] || '',
    };
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-program-template': TASProgramTemplateModel;
  }
}
