import { lazy, object, string, ref } from 'yup';
import 'yup-phone-lite';

const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,64}$/;

const resetPasswordFormSchema = object({
  password: lazy((_, { context }) => {
    return string()
      .min(
        context.passwordMinLength,
        `Password must be at least ${context.passwordMinLength} characters`
      )
      .max(64, 'Password must not exceed 64 characters')
      .matches(
        passwordRegExp,
        'Password must contain 1 upper case letter, 1 lower case letter, 1 number, and 1 special character'
      )
      .required('Required');
  }),
  passwordConfirmation: string()
    .oneOf([ref('password')], 'Passwords must match')
    .required('Required'),
});

export default resetPasswordFormSchema;
