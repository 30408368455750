import Model, { attr, belongsTo } from '@ember-data/model';
import type MatchPlanModel from './match-plan';

export default class MatchPeriodModel extends Model {
  @attr declare startDate: string;
  @attr declare endDate: string;
  @attr declare periodName: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('match-plan', { async: false, inverse: 'matchPeriods' })
  declare matchPlan: MatchPlanModel;
}
