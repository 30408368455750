import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { getSingleValueForTasField } from '../utils/tuition-assistance/fields.ts';
import { cached } from '@glimmer/tracking';
import type TASApplicationModel from './tas-application.ts';
import type TASAssetModel from './tas-asset.ts';
import type AttachmentModel from './attachment.ts';
import type {
  CustomFieldSignature,
  NumberSingleValueField,
  StringSingleValueField,
} from '../types/tuition-assistance';

export type TASCourseModelFieldsSignature = {
  COURSE_DESCRIPTION: StringSingleValueField;
  COURSE_CREDIT: NumberSingleValueField;
  COURSE_NAME: StringSingleValueField;
  COURSE_NUMBER: StringSingleValueField;
  COURSE_TUITION: NumberSingleValueField;
  COURSE_BOOKS: NumberSingleValueField;
  COURSE_LABS: NumberSingleValueField;
  COURSE_SOFTWARE: NumberSingleValueField;
  COURSE_FEES: NumberSingleValueField;
  COURSE_GRADE: StringSingleValueField;

  /**
   * APPROVED_COURSE_TOTAL
   * @description The total requested reimbursement for this course, despite the misnomer in field name.
   */
  APPROVED_COURSE_TOTAL: NumberSingleValueField;
};

export default class TASCourseModel extends Model {
  @attr declare fields: TASCourseModelFieldsSignature;
  @attr declare customFields: CustomFieldSignature[];
  @attr declare createdAt: string;
  @attr declare updatedAt: string;
  @attr declare isActive: boolean;
  @attr declare isTrial: boolean;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('tas-application', { async: false, inverse: 'tasCourses' })
  declare tasApplication: TASApplicationModel;
  @hasMany('tas-asset', { async: false, inverse: 'tasCourses' })
  declare tasAssets: TASAssetModel[];
  @hasMany('attachment', { async: false, inverse: null })
  declare attachments: AttachmentModel[];

  /**************************
   **  Computed Properties **
   **************************/

  @cached
  get approvalAssets() {
    return Object.freeze(
      this.tasAssets.filter((asset) => asset.classification === 'COURSE_APPROVAL_DOCUMENTS')
    );
  }

  @cached
  get evidenceAssets() {
    return Object.freeze(
      this.tasAssets.filter((asset) => asset.classification === 'COMPLETION_DOCUMENTS')
    );
  }

  get courseDescription() {
    return this.fields?.['COURSE_DESCRIPTION']?.values?.[0] || '';
  }

  get courseCredit() {
    return this.fields?.['COURSE_CREDIT']?.values?.[0] || 0;
  }

  get courseName() {
    return this.fields?.['COURSE_NAME']?.values?.[0] || '';
  }

  get courseNumber() {
    return this.fields?.['COURSE_NUMBER']?.values?.[0] || '';
  }

  get courseGrade() {
    return this.fields?.['COURSE_GRADE']?.values?.[0] || '';
  }

  get reimbursementTotalByCredit() {
    const rate = this.tasApplication?.tasProgramInstance?.tasProgramTemplate?.ratePerCredit;
    const credits = getSingleValueForTasField('COURSE_CREDIT', this.fields) as number;
    if (!rate || !credits) {
      return 0;
    }
    return rate * credits;
  }

  get passingCredit(): number {
    if (!this.hasGrades) {
      return 0;
    }
    const passingGrades =
      this.tasApplication?.tasProgramInstance?.tasProgramTemplate?.passingGrades || [];
    if (passingGrades.includes(this.courseGrade.trim())) {
      return (getSingleValueForTasField('COURSE_CREDIT', this.fields) as number) || 0;
    }
    return 0;
  }

  get courseTotal() {
    const calculateTotalByCreditCredit =
      this.tasApplication.tasProgramInstance.tasProgramTemplate?.calculateTotalByCredit;
    return calculateTotalByCreditCredit ? this.reimbursementTotalByCredit : this.courseExpenses;
  }

  get courseExpenses() {
    const fields = this.fields;

    if (!fields) {
      return 0;
    }

    const costs = [
      fields['COURSE_TUITION']?.values?.[0],
      fields['COURSE_BOOKS']?.values?.[0],
      fields['COURSE_LABS']?.values?.[0],
      fields['COURSE_SOFTWARE']?.values?.[0],
      fields['COURSE_FEES']?.values?.[0],
    ].filter((val) => val !== undefined && val !== null);

    const reduced = costs.reduce((a: number, b: number) => {
      return a + Number(b);
    }, 0);

    return reduced;
  }

  get displayName() {
    return [this.courseNumber, this.courseName]
      .filter((val) => val !== undefined && val !== null)
      .join(' - ');
  }

  get hasGrades() {
    return !!getSingleValueForTasField('COURSE_GRADE', this.fields);
  }

  get hasGradeWhenRequired() {
    return this.tasApplication?.tasProgramInstance?.tasProgramTemplate?.courseGradeRequired
      ? this.hasGrades
      : false;
  }

  get hasRequiredEvidence() {
    return !!this.evidenceAssets.length;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-course': TASCourseModel;
  }
}
