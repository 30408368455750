import { registerCustomStyles, tv, useStyles } from '@frontile/theme';

const components = useStyles();

registerCustomStyles({
  ...components,
  button: tv({
    ...components.button,
    base: 'rounded px-4 py-2 text-base font-semibold transition-all text-base',
    variants: {
      ...components.button.variants,
      appearance: {
        ...components.button.variants.appearance,
        outlined:
          'border border-ocean-600 text-ocean-600 hover:text-white hover:bg-ocean-600 rounded',
        default:
          'text-base inline-block font-semibold border border-transparent rounded disabled:cursor-not-allowed disabled:opacity-40 outline-none focus-visible:z-10 focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-offset-background focus-visible:ring-primary-500 text-base px-4 py-2 bg-primary-500 text-primary-foreground hover:bg-primary-500/80 relative',
        minimal: 'text-ocean-600 hover:px-4 hover:py-2 hover:bg-ocean-600 hover:text-white',
      },
    },
    compoundVariants: [
      {
        appearance: 'outlined',
        intent: 'primary',
        class: 'text-ocean-600 hover:text-white border border-ocean-600 hover:bg-ocean-600',
      },
      {
        appearance: 'outlined',
        intent: 'danger',
        class: 'text-danger hover:text-white border border-danger hover:bg-danger relative',
      },
      {
        appearance: 'default',
        intent: 'danger',
        class: 'bg-danger text-danger-foreground hover:bg-danger/80',
      },
      {
        appearance: 'minimal',
        intent: 'primary',
        class: 'text-ocean-600 hover:text-white hover:bg-ocean-600',
      },
    ],
  }) as never,
  label: tv({
    ...components.label,
    slots: {
      base: 'font-normal',
      // The type requires we specify this slot. I think this is a bug in the lib.
      asterisk: 'text-danger',
    },
  }) as never,
  input: tv({
    extend: components.input,
    base: 'font-normal',
    slots: {
      input: 'dark:bg-white',
      innerContainer: 'font-normal',
    },
  }) as never,
  listbox: tv({
    base: 'bg-content1 w-[260px] flex flex-col gap-0.5 outline-none p-1',
  }) as never,
});
