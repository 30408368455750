import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type { ISO8601Duration, MatchPlanMeta, ComplexMatch } from '../types/secure-your-future.ts';

import type CompanyModel from './company.ts';
import type MatchPeriodModel from './match-period.ts';

export type SyfQualifyingIncome =
  | 'SYF.QualifyingIncome.PRETAX_ANNUAL'
  | 'SYF.QualifyingIncome.PRETAX_QUARTERLY'
  | 'SYF.QualifyingIncome.TAXED_ANNUAL'
  | 'SYF.QualifyingIncome.TAXED_QUARTERLY';

// NB: backend class that models this is the ruby Jsonb::GenericTextStore
export type MatchPlanFaq = {
  ordinal: number;
  heading: string;
  subheading?: string;
  body: string;
};

export default class MatchPlanModel extends Model {
  @service declare intl: IntlService;

  @attr declare benefitStartDate: string;
  @attr declare benefitWaitingPeriod: ISO8601Duration;
  @attr declare benefitStartIsRetroactive: boolean;
  @attr declare code: string;
  @attr declare complexMatch: Array<ComplexMatch>;
  @attr declare description: string;
  @attr declare faqs: Array<MatchPlanFaq>;
  @attr declare fileDateBuffer: ISO8601Duration;
  @attr declare information: Array<unknown>;
  @attr declare matchFrequencyDescription: string;
  @attr declare matchPercentage: number;
  @attr declare matchStatement: string;
  @attr declare maxMatchCap: number;
  @attr declare maxMatchPercentage: number;
  @attr declare metadata: MatchPlanMeta;
  @attr declare minimumThresholdPercentage: number;
  @attr declare periodBuffer: ISO8601Duration;
  @attr declare periodCadence: ISO8601Duration;
  @attr declare periodNamingScheme: string;
  @attr declare planName: string;
  @attr declare qualifyingIncome: SyfQualifyingIncome;
  @attr declare requiresEmployeeCertification: boolean;
  @attr declare verifyAccountStatement: string;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: 'matchPlans' })
  declare company: CompanyModel;

  @hasMany('match-period', { async: false, inverse: 'matchPlan' })
  declare matchPeriods: MatchPeriodModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get ensureVerifyAccountStatement() {
    return (
      this.verifyAccountStatement || this.intl.t('syf.shared.match_plan.default_verify_statement')
    );
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'match-plan': MatchPlanModel;
  }
}
