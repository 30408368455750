import { helper } from '@ember/component/helper';

export type FlattenedValibotErrors = {
  [key: string]: readonly string[] | undefined;
};
export type Touched = {
  [key: string]: boolean;
};

/**
 * Returns a readonly array of error messages for a given field from a flattened Valibot error object.
 *
 * @param {array} positional - The positional parameters for this function.
 * @param {string} positional[0] - The field name to check for errors.
 * @param {object} named - The named parameters.
 * @param {FlattenedValibotErrors} named.schemaErrors - The flattened Valibot error object.
 * @param {Touched} [named.touched] - Optional object tracking which fields have been touched.
 *
 * @return {string[]} A array of error messages for the given field.
 */

function errorsForValibotField(
  [field]: [string],
  { schemaErrors = {}, touched = {} }: { schemaErrors?: FlattenedValibotErrors; touched?: Touched }
): string[] {
  if ((!field && field !== '0') || !schemaErrors || (touched && !touched[field])) {
    return [];
  }
  const fieldErrors = schemaErrors[field];
  // Convert the readonly array to a mutable array without altering the original as frontile @errors expects a mutable array
  return fieldErrors ? [...fieldErrors] : [];
}

export default helper(errorsForValibotField);
