import type ObservableDocumentModel from '../models/observable-document';
import type {
  AccountStatementStructuredContent,
  NsldsStructuredContent,
  AccountTxnHistoryStructuredContent,
} from '../models/observable-document';

/*
  this is... relatively experimental; motivation here was to try to improve on the abstraction
  of imperatively splitting an AASM string and accessing the meaningful state part via index,
  the motivation for which is to be able to more sanely structure ember-intl translation YAML
  select statements

  it seems that translation *keys* can include a period(1)(2) since sometime after this issue(3)
  was closed, but anecdotally ember-intl throws an error in reference to an invalid translation
  format when any cases in a select statement include a period (which all AASM state strings do,
  sometimes more than 1), hence the motivation to generically isolate the meaningful segment of
  a string with that format

  as we hopefully continue to adopt select statements in translations, it seems likely that AASM
  strings will be a common use case for them, and therefore reasonable that we have a generic
  strategy to get from AASM strings to select statement cases

  while in practice calling .split('.').at(-1) incidentally accomplishes the same thing, I
  propose an implementation around a language feature that more declaratively supports
  destructuring strings into optionally captured substrings that can also be optionally named
  something that corresponds to their semantic meaning; I would argue this is a strictly better
  abstraction than relying on positionality relative to delimiters

  james 20241127

  (1) https://github.com/ember-intl/ember-intl/blob/0d9bcf7cec03f716a1d14da2199fe7d32cc077d1/tests/ember-intl/tests/unit/services/intl-test.ts#L57
  (2) https://ember-intl.github.io/ember-intl/docs/migration/v3
  (3) https://github.com/ember-intl/ember-intl/issues/581

  also,
  TODO: put this in a utils module (tio-common sounds better than tio-ui for that?) if we don't
  decide that it's completely insane - james 20241127
*/
const parseAasmState = (fullState: string): string => {
  // (?:[A-Z]+(?:_[A-Z]+)*\.)? - optional SCREAMING_SNAKE prefix followed by "." (inner/outer both groups uncaptured)
  // (?:[A-Z][a-z]+)+\. - mandatory PascalCase flavor string followed by "." (group uncaptured)
  // ([A-Z]+(?:_[A-Z]+)*) - mandatory SCREAMING_SNAKE state string, named group 'state' (inner "(?:_[A-Z]+)*" grouped for repetition but not captured)
  const aasmStatePattern =
    /(?:[A-Z]+(?:_[A-Z]+)*\.)?(?:[A-Z][a-z]+)+\.(?<state>[A-Z]+(?:_[A-Z]+)*)/g;

  // use matchAll here to get groupings
  const match = Array.from(fullState.matchAll(aasmStatePattern));

  // ack if this received a bad fullState argument; any model with a prop for an AASM attribute
  // should always have at least *a* value for it and that value should strictly be an AASM string
  if (!match.length) {
    throw new Error(`parseAasmState called with invalid string - ${fullState}`);
  }

  // spread match iterator into an array literal and cast it as a one-element array
  // with a full match and a captured group since it should be known by now that the
  // full state string matched the AASM pattern regexp
  const [
    {
      groups: { state },
    },
  ] = <[{ groups: { state: string } }]>(<unknown>[...match]);
  return state;
};

/**********************/
/* DOCUMENT ACCESSORS */
/**********************/

export const getStatus = (document: ObservableDocumentModel): string => {
  // only subsistence state we care about currently; condition may change in the future
  // to match applied reflection state in order to return susbsistence state
  if (document.subsistenceState === 'SubsistenceState.IN_DISPUTE') {
    return parseAasmState(document.subsistenceState);
  } else if (document.extractionState === 'ExtractionState.PROCESSED') {
    return parseAasmState(document.reflectionState);
  } else {
    return parseAasmState(document.extractionState);
  }
};

export const getProvider = (document: ObservableDocumentModel): string => {
  return parseAasmState(document.provider);
};

// TODO: sanitize observable document logs on staging/vodka and delete any
// of them without notes in the detail object in order to purge question
// mark operators here
// TODO: consider isDisputed or similar instance method on observable document
// log model to replace find operation, avoid need for ? operators altogether
export const getDisputeNote = (document: ObservableDocumentModel): string | undefined => {
  // get associated logs and find any dispute logs
  // TODO: get this from a logSource attribute when available rather than the detail JSON
  const logs = document.observableDocumentLogs || [];
  const disputeLog = logs.find((log) => {
    return Object.keys(log.detail.changes).includes('disputed_at');
  });

  return disputeLog?.detail?.note;
};

export const getServicerName = (document: ObservableDocumentModel): string | undefined => {
  if (document.provider === 'ObservableProvider.ACCOUNT_STATEMENT') {
    return (<AccountStatementStructuredContent>document.structuredContent)?.servicer_name;
  }
};

export const getBalance = (document: ObservableDocumentModel): number | undefined => {
  if (document.provider === 'ObservableProvider.ACCOUNT_STATEMENT') {
    return (
      ((<AccountStatementStructuredContent>document.structuredContent)?.principal_balance_amount ||
        0) / 100
    );
  }
};

export const getAccountNumber = (document: ObservableDocumentModel): string | undefined => {
  if (document.provider === 'ObservableProvider.ACCOUNT_STATEMENT') {
    return (<AccountStatementStructuredContent>document.structuredContent)?.account_number;
  }
};

export const getLoans = (document: ObservableDocumentModel) => {
  if (document.provider === 'ObservableProvider.ACCOUNT_STATEMENT') {
    return Object.values(
      (<AccountStatementStructuredContent>document.structuredContent)?.loan_details || []
    ).map((loan: AccountStatementStructuredContent['loan_details'][number]) => ({
      name: loan.loan_name || loan.loan_type,
      balance: parseInt(loan.loan_current_principal_balance),
      originated: loan.loan_disbursement_date,
    }));
  }
  if (document.provider === 'ObservableProvider.NSLDS') {
    return ((<NsldsStructuredContent>document.structuredContent)?.loans || [])
      .filter((loan: NsldsStructuredContent['loans'][number]) => {
        return !['NON-DEFAULTED, PAID IN FULL THROUGH CONSOLIDATION LOAN', 'PAID IN FULL'].includes(
          loan.current_loan_status_description
        );
      })
      .map((loan: NsldsStructuredContent['loans'][number]) => ({
        name: loan.loan_type_description || loan.loan_type_code,
        balance: parseInt(loan.loan_outstanding_principal_balance),
        originated: loan.loan_date,
      }));
  }
};

export const getTransactions = (
  document: ObservableDocumentModel
): AccountTxnHistoryStructuredContent['transactions'][number][] | undefined => {
  if (document.provider === 'ObservableProvider.ACCOUNT_TXN_HISTORY') {
    return (<AccountTxnHistoryStructuredContent>document.structuredContent)?.transactions || [];
  }
};
